export const SALES_DAYS = [30, 20, 10];
export const DEFAULT_DAYS = 30;
export const DEFAULT_CATEGORY = 'main';
export const MAP_CATEGORIES = {
	MainInventory: 'main',
	LitesInventory: 'lites',
	BooksInventory: 'books',
	VPackInventory: 'vpack',
	SwagInventory: 'swag',
	WearablesInventory: 'wearables',
	JeunesseSellInventory: 'jns_sell',
	JeunesseOldInventory: 'jns_old',
	AllInventory: 'main,vpack,swag,wearables,lites,books,jns_sell,jns_old',
};
export const UPDATE_STOCK_OPERATIONS = ['increment', 'decrement'];

export default {};
