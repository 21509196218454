<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		id="update_stock_status"
		:ok-disabled="okDisabled"
		:cancel-disabled="loading"
		centered
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
		@ok.prevent="updateStockStatus()"
		@cancel="clearAll()">
		<template v-slot:modal-title>
			{{ translate('update_stock_status') }}
		</template>
		<template v-slot:modal-ok>
			<i
				v-if="loading"
				class="fas fa-spinner fa-spin" />
			{{ translate('update') }}
		</template>
		<div>
			<div
				v-if="item.attributes"
				class="table-responsive mb-3">
				<table
					class="table table-sm bg-light"
					style="border-radius: 8px;">
					<thead>
						<tr>
							<th
								class="bg-light align-middle text-center"
								style="border-top-left-radius: 8px">
								{{ translate('warehouse') }}
							</th>
							<th
								class="bg-light align-middle text-center">
								{{ translate('product') }}
							</th>
							<th
								class="bg-light align-middle text-center"
								style="border-top-right-radius: 8px">
								{{ translate('current_status') }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="align-middle text-center">
								{{ item.attributes.warehouse_name }}
							</td>
							<td class="align-middle text-center">
								<img
									v-if="item.attributes.image !== null"
									:src="item.attributes.image"
									class="responsive block-inline"
									style="max-width:32px"
									alt="product image">
								{{ translate(item.attributes.code_name) }}
							</td>
							<td class="align-middle text-center">
								{{ translate(item.attributes.stock_status) }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				class="text-center mb-2"
				v-html="translate('select_new_status')" />
			<div class="d-flex justify-content-center align-items-center">
				<b-button-group>
					<b-button
						v-for="status in statuses"
						:key="status"
						:variant="status === selectedStatus ? 'primary' : 'secondary'"
						@click="selectedStatus = status"
						v-text="translate(status)" />
				</b-button-group>
			</div>
			<div class="mt-2">
				<b-form
					ref="updateStatusForm"
					@submit.stop.prevent>
					<div>
						<label for="notes">{{ translate('notes') }} <span class="text-danger">*</span></label>
						<b-form-textarea
							id="notes"
							v-model.trim="notes.value"
							:state="notesValidation"
							@keydown="notes.modified = true" />
						<b-form-invalid-feedback :state="notesValidation">
							{{ translate('notes_required') }}
						</b-form-invalid-feedback>
					</div>
					<div class="mt-2">
						<label for="password">{{ translate('password') }} <span class="text-danger">*</span></label>
						<div class="form-inline">
							<b-form-input
								id="password"
								v-model.trim="password.value"
								:state="passwordValidation"
								:type="password.hidden ? 'password' : 'text'"
								class="w-100"
								@keydown="password.modified = true" />
							<span
								class="display-eye fa"
								:class="{
									'fa-eye-slash': password.hidden,
									'fa-eye': !password.hidden,
								}"
								@click="password.hidden = !password.hidden" />
						</div>
						<b-form-invalid-feedback :state="passwordValidation">
							{{ translate('password_required') }}
						</b-form-invalid-feedback>
					</div>
				</b-form>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { Inventory as InventoryMessages, Products } from '@/translations';
import Inventory from '@/util/Inventory';

export default {
	name: 'UpdateStockStatusModal',
	messages: [InventoryMessages, Products],
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			inventory: new Inventory(),
			inventoryStatuses: new Inventory(),
			alert: new this.$Alert(),
			statuses: this.allStatuses,
			selectedStatus: '',
			notes: {
				value: '',
				modified: false,
			},
			password: {
				value: '',
				modified: false,
				hidden: true,
			},
		};
	},
	computed: {
		loading() {
			return !!this.inventory.data.loading;
		},
		okDisabled() {
			if (typeof this.item.attributes === 'undefined') {
				return true;
			}
			return this.loading || this.currentStatusSelected || !this.passwordValidation || !this.notesValidation;
		},
		currentStatusSelected() {
			return this.item.attributes.stock_status === this.selectedStatus;
		},
		passwordValidation() {
			if (this.password.modified) {
				return this.password.value.length > 0;
			}
			return null;
		},
		notesValidation() {
			if (this.notes.modified) {
				return this.notes.value.length > 0;
			}
			return null;
		},
		allStatuses() {
			try {
				const { data } = this.inventoryStatuses.data.response.data;
				return data.map((status) => status.attributes.code_name);
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		item() {
			this.setCurrentStatus();
		},
	},
	mounted() {
		this.inventoryStatuses.getStatuses();
	},
	methods: {
		setCurrentStatus() {
			if (typeof this.item.attributes !== 'undefined') {
				this.statuses = this.allStatuses.filter((status) => status !== this.item.attributes.stock_status);
				if (this.statuses.length === 1) {
					[this.selectedStatus] = this.statuses;
				}
			}
		},
		clearAll() {
			[this.password.value, this.password.modified, this.password.hidden, this.notes.value, this.notes.modified] = ['', false, true, '', false];
			this.setCurrentStatus();
		},
		updateStockStatus() {
			const payload = {
				warehouse_id: this.item.attributes.warehouse_id,
				product_id: this.item.id,
				status: this.allStatuses.find((status) => status === this.selectedStatus),
				password: this.password.value,
				notes: this.notes.value,
			};
			this.inventory.updateStockStatus(payload).then(() => {
				this.$bvModal.hide('update_stock_status');
				this.clearAll();
				this.$emit('updated', true);
				this.alert.toast('success', this.translate('stock_status_updated'));
			}).catch(() => {
				let errorName = 'default_error_message';
				if (typeof this.inventory.data.errors.errors.password !== 'undefined') {
					errorName = 'incorrect_password';
					delete this.inventory.data.errors.errors.password;
				}
				this.alert.toast('error', this.translate(errorName));
			});
		},
	},
};
</script>

<style scoped>
.form-control {
	border-color: #e4e7ea !important;
	background-image: none !important;
}

.form-control.is-invalid {
	border-color: #f86c6b !important;
}

.form-control.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
	box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
}

#password {
	margin-right: -30px;
	padding-right: 35px;
}

.display-eye {
	cursor: pointer;
}

table thead tr {
	background: transparent;
	color: black;
}

table thead tr th {
	border-bottom: #4d4d4d;
	border-top-width: 0
}
</style>
