<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th class="border-top-0">
							{{ translate('warehouse') }}
						</th>
						<th class="border-top-0">
							{{ translate('product') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('sku') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('current_quantity') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('quantity') }}
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading">
					<tr
						v-for="product in products"
						:key="`${product.attributes.sku}-${product.warehouse_name}`">
						<td class="align-middle">
							{{ product.warehouse_name }}
						</td>
						<td class="align-middle">
							<img
								v-if="product.attributes.image !== null"
								:src="product.attributes.image"
								class="responsive block-inline"
								style="max-width:32px">
							<div
								v-else
								style="width:32px; display:inline-block;" />
							{{ translate(product.attributes.code_name) }}
						</td>
						<td class="align-middle">
							{{ product.attributes.sku }}
						</td>
						<td class="align-middle text-center">
							{{ product.attributes.available_qty }}
						</td>
						<td class="align-middle">
							<div class="d-flex justify-content-center">
								<b-input
									v-model="product.quantity"
									class="text-right"
									style="max-width: 80px"
									type="number"
									@change="changeQuantity(product, $event)" />
							</div>
						</td>
						<td class="align-middle text-center">
							<b-button
								v-b-tooltip.hover
								:title="translate('delete_thing', { name: translate('product') })"
								variant="primary"
								@click="removeItem(product)">
								<i
									class="fa fa-trash"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
			<is-loading
				:loading="loading"
				:has-data="Object.keys(products).length > 0"
				:no-data-label="translate('no_products_selected')" />
		</div>
	</div>
</template>
<script>
import {
	Inventory, Tooltip, Products,
} from '@/translations';

export default {
	name: 'ProductsTable',
	messages: [Inventory, Tooltip, Products],
	props: {
		productsImport: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: true,
		},
		initQty: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			products: this.productsImport,
		};
	},
	watch: {
		productsImport(productsImport) {
			this.products = productsImport.map((product) => ({ ...product, quantity: product.quantity || this.initQty }));
		},
	},
	methods: {
		changeQuantity(item, qty) {
			item.quantity = qty < 0 ? 0 : qty;
			this.emitProducts();
		},
		removeItem(item) {
			this.products.splice(this.products.findIndex((product) => product.id === item.id && product.attributes.warehouse_id === item.attributes.warehouse_id), 1);
			this.emitProducts();
		},
		emitProducts() {
			this.$emit('update:products', this.products);
		},
	},
};
</script>
