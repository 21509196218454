<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		id="transfer_stock_modal"
		:ok-disabled="okDisabled"
		:cancel-disabled="loading"
		centered
		size="lg"
		hide-header-close
		no-close-on-esc
		no-close-on-backdrop
		@ok.prevent="transferStock()"
		@cancel="clearAll()">
		<template v-slot:modal-title>
			{{ translate('move_stock') }}
		</template>
		<template v-slot:modal-ok>
			<i
				v-if="loading"
				class="fas fa-spinner fa-spin" />
			{{ translate('move') }}
		</template>
		<div>
			<b-row>
				<b-col :cols="['xs', 'sm'].includes(windowWidth) ? '12' : '6'">
					<item-select
						id="from_warehouse"
						class="warehouse-item-select"
						:title="translate('from_warehouse')"
						:placeholder="translate('from_warehouse')"
						:options="warehouses"
						:selected-item.sync="selectedWarehouse" />
					<p
						class="mx-1 pt-0 text-muted small">
						*{{ translate('warehouse_disclaimer') }}
					</p>
				</b-col>
				<b-col :cols="['xs', 'sm'].includes(windowWidth) ? '12' : '6'">
					<item-select
						id="to_warehouse"
						class="warehouse-item-select"
						:title="translate('to_warehouse')"
						:placeholder="translate('to_warehouse')"
						:options="toWarehouses"
						:selected-item.sync="selectedToWarehouse" />
				</b-col>
				<b-col :cols="['xs', 'sm'].includes(windowWidth) ? '12' : '6'">
					<div class="form-group">
						<label for="is_in_transit">
							{{ translate('is_in_transit') }}
						</label>
						<switch-toggle
							id="is_in_transit"
							v-model="isInTransit"
							name="is_in_transit"
							variant="success"
							class="ml-2"
							pill />
					</div>
				</b-col>
			</b-row>
			<item-select
				id="products"
				:disabled="selectedWarehouse === null || productsLoading"
				:title="translate('products')"
				:placeholder="translate('products')"
				reset-after
				:close-on-select="false"
				:options="products"
				:selected-item.sync="selectedProduct">
				<products-table
					:products-import="tableProducts"
					:loading="productsLoading"
					:products.sync="tableProducts" />
			</item-select>
			<div class="mt-2">
				<b-form
					ref="updateStatusForm"
					@submit.stop.prevent>
					<div>
						<label for="notes">{{ translate('notes') }} <span class="text-danger">*</span></label>
						<b-form-textarea
							id="notes"
							v-model.trim="notes.value"
							:state="notesValidation"
							@keydown="notes.modified = true" />
						<b-form-invalid-feedback :state="notesValidation">
							{{ translate('notes_required') }}
						</b-form-invalid-feedback>
					</div>
					<div class="mt-2">
						<label for="password">{{ translate('password') }} <span class="text-danger">*</span></label>
						<div class="form-inline">
							<b-form-input
								id="password"
								v-model.trim="password.value"
								:state="passwordValidation"
								:type="password.hidden ? 'password' : 'text'"
								class="w-100"
								@keydown="password.modified = true" />
							<span
								class="display-eye fa"
								:class="{
									'fa-eye-slash': password.hidden,
									'fa-eye': !password.hidden,
								}"
								@click="password.hidden = !password.hidden" />
						</div>
						<b-form-invalid-feedback :state="passwordValidation">
							{{ translate('password_required') }}
						</b-form-invalid-feedback>
					</div>
				</b-form>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { Inventory as InventoryMessages, Products } from '@/translations';
import Inventory from '@/util/Inventory';
import ProductsTable from '@/views/Inventory/ProductsTable';
import ItemSelect from '@/components/ItemSelect';
import WindowSizes from '@/mixins/WindowSizes';
import Switch from '@/components/Switch';

export default {
	name: 'TransferStockModal',
	messages: [InventoryMessages, Products],
	components: {
		ProductsTable,
		ItemSelect,
		'switch-toggle': Switch,
	},
	mixins: [WindowSizes],
	data() {
		return {
			inventoryUpdate: new Inventory(),
			inventoryProducts: new Inventory(),
			inventoryWarehouses: new Inventory(),
			alert: new this.$Alert(),
			warehouses: [],
			selectedWarehouse: null,
			selectedToWarehouse: null,
			allProducts: [],
			products: [],
			selectedProduct: null,
			tableProducts: [],
			items: [],
			notes: {
				value: '',
				modified: false,
			},
			password: {
				value: '',
				modified: false,
				hidden: true,
			},
			isInTransit: false,
		};
	},
	computed: {
		toWarehouses() {
			return this.warehouses.filter((item) => item.id !== this.selectedWarehouse?.id) || [];
		},
		productsLoading() {
			return !!this.inventoryProducts.data.loading;
		},
		loading() {
			return !!this.inventoryUpdate.data.loading;
		},
		okDisabled() {
			if (this.tableProducts.length === 0 || this.tableProducts.every((product) => (Number(product.quantity) || 0) === 0)) {
				return true;
			}
			return this.loading || !this.passwordValidation || !this.notesValidation || !this.selectedToWarehouse;
		},
		passwordValidation() {
			if (this.password.modified) {
				return this.password.value.length > 0;
			}
			return null;
		},
		notesValidation() {
			if (this.notes.modified) {
				return this.notes.value.length > 0;
			}
			return null;
		},
	},
	watch: {
		selectedProduct(selected) {
			if (selected === null) return;
			if (this.selectedWarehouse === null) return;
			this.tableProducts.push({ ...selected, warehouse_name: this.selectedWarehouse.attributes.name });
		},
		selectedWarehouse(selected) {
			this.tableProducts = [];
			if (selected) {
				this.getProducts(selected.id);
				if (selected.id === this.selectedToWarehouse?.id) {
					this.selectedToWarehouse = null;
				}
			}
		},
		tableProducts() {
			this.products = this.nonSelectedProducts();
		},
	},
	mounted() {
		this.getWarehouses();
	},
	methods: {
		nonSelectedProducts() {
			return this.allProducts.filter((product) => !this.tableProducts.some((item) => item.id === product.id && Number(item.attributes.warehouse_id) === Number(this.selectedWarehouse.id)));
		},
		getProducts(warehouseId) {
			this.inventoryProducts.getProducts({ warehouse_id: warehouseId }).then((response) => {
				this.allProducts = response
					.map((item) => ({
						...item,
						value: item.attributes.sku,
						text: item.attributes.name,
					}));
				this.products = this.nonSelectedProducts();
			});
		},
		getWarehouses() {
			this.inventoryWarehouses.getWarehouses().then((response) => {
				this.warehouses = response
					.map((item) => ({
						...item,
						value: item.id,
						text: item.attributes.name,
					}));
			});
		},
		clearAll() {
			[this.password.value, this.password.modified, this.password.hidden, this.notes.value, this.notes.modified, this.isInTransit] = ['', false, true, '', false, false];
			this.tableProducts = [];
			this.selectedWarehouse = null;
			this.selectedToWarehouse = null;
		},
		transferStock() {
			const payload = {
				changes: this.tableProducts
					.map((product) => ({
						warehouse_id: product.attributes.warehouse_id,
						product_id: product.id,
						qty: Number(product.quantity) || 0,
						to_warehouse_id: this.selectedToWarehouse.id,
					}))
					.filter((item) => item.qty !== 0),
				in_transit: this.isInTransit ? 1 : 0,
				password: this.password.value,
				notes: this.notes.value,
			};
			this.inventoryUpdate.transferStock(payload).then(() => {
				this.$bvModal.hide('transfer_stock_modal');
				this.clearAll();
				this.$emit('performed', true);
				this.alert.toast('success', this.translate('movement_recorded'));
			}).catch(() => {
				let errorName = 'default_error_message';
				if (typeof this.inventoryUpdate.data.errors.errors.password !== 'undefined') {
					errorName = 'incorrect_password';
					delete this.inventoryUpdate.data.errors.errors.password;
				}
				this.alert.toast('error', this.translate(errorName));
			});
		},
	},
};
</script>

<style scoped>
.form-control {
	border-color: #e4e7ea !important;
	background-image: none !important;
}

.form-control.is-invalid {
	border-color: #f86c6b !important;
}

.form-control.is-invalid:focus {
	-webkit-box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
	box-shadow: 0 0 0 0.2rem rgb(248 108 107 / 25%) !important;
}

#password {
	margin-right: -30px;
	padding-right: 35px;
}

.display-eye {
	cursor: pointer;
}
.warehouse-item-select::v-deep .form-group {
	margin-bottom: 0.25rem;
}
</style>
